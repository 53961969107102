var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import DataBox from "~/components/common/data-box.vue";
import DataForm from "~/components/common/data-form.vue";
import NumberRange from "~/components/common/number-range.vue";
import { State } from "vuex-class";
import { Layout } from "~/core/decorator";
import { Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import { SortService } from "~/utils/sort.service";
import { DistributeCaseService } from "~/services/domain-service/distribute-case.service";
import { CollectionCaseService } from "~/services/domain-service/collection-case.service";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import { MENU_CHANNEL } from "~/config/enum.business";
import RetireCase from "~/components/case-manage/case-all-manage/retire-case.vue";
import AdjustmentCaseAllot from "~/components/case-info/adjustment-case-allot.vue";
import AdjustmentCaseBatchAllot from "~/components/case-info/adjustment-case-batch-allot.vue";
import FollowRecordExport from "~/components/case-manage/case-all-manage/follow-record-export.vue";
import caseExport from "~/components/case-manage/case-all-manage/case-export.vue";
import RefreshRetirementTime from "~/components/case-adjustment/refresh-retirement-time.vue";
import ImportBatchRetireCase from "~/components/case-manage/case-all-manage/import-batch-retire-case.vue";
import DeleteFollowRecord from "~/components/case-manage/case-adjustment/delete-follow-record.vue";
import DelCaseBatchNumberForever from "~/components/case-manage/case-adjustment/del-case-batch-number-forever.vue";
import AppendWorkOrder from "~/components/case-manage/case-adjustment/append-work-order.vue";
import FollowAllQueryBatch from "~/components/case-manage/case-adjustment/follow-all-query-batch.vue";
import UpdateBaseCase from "~/components/case-manage/case-all-manage/update-base-case.vue";
var AlreadyDistributeCase = /** @class */ (function (_super) {
    __extends(AlreadyDistributeCase, _super);
    function AlreadyDistributeCase() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.props = {
            value: "departmentCode",
            label: "name",
            children: "children",
        };
        _this.exportData = {};
        _this.options = [];
        _this.idsList = []; //退案时传入退案页面的id集合
        _this.currentCase = {};
        // 个人未下发
        _this.caseIssuedFlag = "PERSONAL_HAS_ISSUED";
        _this.channel = MENU_CHANNEL.DISTRIBUTE_PERSONAL;
        _this.operateId = "";
        _this.currentDate = {};
        _this.dialog = {
            retirecase: false,
            adjustmentManualDivision: false,
            batchDivision: false,
            caseExport: false,
            retirement: false,
            batchRetireCase: false,
            deleteFollowCase: false,
            batchNumberDelForever: false,
            workerOrder: false,
            detail: false,
            updateCase: false,
        };
        _this.loading = {
            state: false,
        };
        _this.selectionList = [];
        _this.batchNumberList = [];
        _this.searchModel = {
            departCodes: [],
            principalId: "",
            casePool: _this.caseIssuedFlag,
            batchNumber: "",
            departCode: "",
            currentCollectorName: "",
            userName: "",
            name: "",
            certificateNo: "",
            city: "",
            handsNumber: "",
            phone: "",
            delegationDate: [],
            endCaseDate: [],
            cardNo: "",
            address: "",
            account: "",
            retireCaseDays: "",
            employerName: "",
            delegationMonth: "",
            cardNoType: "",
            unhealthyFlag: "",
            receiveName: "",
            batchNo: "",
            extensionInfo: "",
            extensionAgent: "",
            leftAmt: {
                min: "",
                max: "",
            },
            overduePeriods: {
                min: "",
                max: "",
            },
            overdueDays: {
                min: "",
                max: "",
            },
        };
        _this.dataSet = [];
        _this.collectorDate = [];
        _this.rules = {
            certificateNo: [{ min: 4, message: "请输入至少4位的身份证号", trigger: "blur" }],
            phone: [{ min: 4, message: "请输入至少4位的手机号", trigger: "blur" }],
            // cardNo: [{min: 6, message: "请输入至少6位的卡号", trigger: "blur"}],
            account: [{ min: 6, message: "请输入至少6位的账号", trigger: "blur" }],
        };
        _this.optionsList = [];
        return _this;
    }
    /**
     * 钩子函数
     */
    AlreadyDistributeCase.prototype.mounted = function () {
        this.collectorDate = this.collectorList.userNameList.map(function (x) {
            return { value: x.name, userName: x.userName };
        });
        // 构造
        this.createTree();
        this.refreshData();
    };
    AlreadyDistributeCase.prototype.querySearch = function (queryString, cb) {
        var results = queryString ? this.collectorDate.filter(this.createFilter(queryString)) : this.collectorDate;
        cb(results);
    };
    AlreadyDistributeCase.prototype.createFilter = function (queryString) {
        return function (restaurant) {
            return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
        };
    };
    /**
     * 刷新数据列
     */
    AlreadyDistributeCase.prototype.refreshData = function () {
        var _this = this;
        this.loading.state = true;
        this.distributeCaseService.searchDetalPersonalDisCase(this.searchModel, this.pageService, this.sortService, this.loading).subscribe(function (data) { return (_this.dataSet = data); });
    };
    /**
     * 级联选择改变事件
     */
    AlreadyDistributeCase.prototype.handleChange = function (value) {
        if (value && value.length) {
            this.searchModel.departCode = value[value.length - 1];
        }
        else {
            this.searchModel.departCode = "";
        }
    };
    /**
     * 创建数据树
     */
    AlreadyDistributeCase.prototype.createTree = function () {
        var _this = this;
        // 递归构建组织树
        var fun = function (id) {
            // 递归对象子元素
            var list = _this.departmentList
                .filter(function (x) { return x.parent === id; })
                .map(function (node) {
                // 递归构建子节点
                var children = fun(node.id);
                // 节点添加children或leaf属性
                if (children && children.length) {
                    // 设置子元素
                    node.children = children;
                }
                return node;
            });
            return list;
        };
        var parent = this.departmentList.find(function (x) { return x.id === _this.userData.deptId; });
        var flag = true;
        if (!parent) {
            flag = false;
        }
        this.options = flag ? fun(parent.parent) : [];
    };
    /**
     * 搜索条件重置
     */
    AlreadyDistributeCase.prototype.reset = function () {
        var form = this.$refs["form"];
        if (form) {
            form.resetForm();
        }
        this.searchModel.departCodes = [];
    };
    AlreadyDistributeCase.prototype.handleResetForm = function () {
        this.searchModel.departCodes = [];
    };
    AlreadyDistributeCase.prototype.onAdjustmentManualDivisionOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["adjustment-case-allot"];
            form.refreshData();
        });
    };
    AlreadyDistributeCase.prototype.onBatchDivisionOpen = function () {
        var _this = this;
        this.$nextTick(function () {
            var form = _this.$refs["adjustment-case-batch-allot"];
            form.refreshData();
        });
    };
    AlreadyDistributeCase.prototype.refreshDataTwo = function () { };
    /**
     * 停调
     */
    AlreadyDistributeCase.prototype.stopCase = function () {
        var _this = this;
        if (!this.selectionList.length) {
            this.$message("请选择要停调的案件！");
            return;
        }
        var idList = this.selectionList.map(function (v) { return v.id; });
        this.$confirm("确定停调已选择的案件吗？", "提示", { type: "warning" }).then(function () {
            _this.collectionCaseService.stopCollectionCase({ idList: idList }).subscribe(function (data) {
                _this.$message.success("操作成功！");
                _this.refreshData();
            }, function (_a) {
                var msg = _a.msg;
            });
        });
    };
    /**
     * 退案
     */
    AlreadyDistributeCase.prototype.returnCase = function () {
        if (!this.selectionList.length) {
            this.$message("请选择要退案的案件！");
            return;
        }
        this.idsList = this.selectionList.map(function (v) { return v.id; });
        this.dialog.retirecase = true;
    };
    /**
     * 手动重新分案
     */
    AlreadyDistributeCase.prototype.onManualSeparate = function () {
        if (this.selectionList.length === 0) {
            this.$message.info("请选择要分配的案件");
            return;
        }
        this.dialog.adjustmentManualDivision = true;
    };
    /**
     * 批次分配
     */
    AlreadyDistributeCase.prototype.onBatchDivision = function () {
        this.dialog.batchDivision = true;
    };
    /**
     * 调记导出
     */
    AlreadyDistributeCase.prototype.followRecordExport = function () {
        var exportModel = {};
        if (this.selectionList.length > 0)
            exportModel.caseIds = this.selectionList.map(function (v) { return v.id; });
        exportModel = Object.assign(exportModel, this.searchModel);
        this.exportData = exportModel;
        this.dialog.followRecordExport = true;
    };
    /**
     * 案件导出
     */
    AlreadyDistributeCase.prototype.caseExport = function () {
        var exportModel = {};
        if (this.selectionList.length > 0)
            exportModel.caseIds = this.selectionList.map(function (v) { return v.id; });
        exportModel = Object.assign(exportModel, this.searchModel);
        this.exportData = exportModel;
        this.dialog.caseExport = true;
    };
    /**
     * 刷新退案时间
     */
    AlreadyDistributeCase.prototype.retirementTime = function () {
        this.currentCase = null;
        this.dialog.retirement = true;
    };
    /**
     * 批量退案导入
     */
    AlreadyDistributeCase.prototype.importBatchRetireCase = function () {
        this.currentCase = null;
        this.dialog.batchRetireCase = true;
    };
    /**
     * 批量调记删除导入
     */
    AlreadyDistributeCase.prototype.deleteFollowCase = function () {
        this.currentCase = null;
        this.dialog.deleteFollowCase = true;
    };
    /**
     * 案件永久删除
     */
    AlreadyDistributeCase.prototype.delCaseForever = function () {
        var _this = this;
        if (this.selectionList.length) {
            var idsForever_1 = this.selectionList.map(function (v) { return v.id; });
            this.$confirm("确定删除已选择的案件吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(function () {
                _this.loading.state = true;
                _this.collectionCaseService.deleteBaseCaseForever({ idsForever: idsForever_1 }, _this.loading).subscribe(function (data) {
                    _this.$message.success("操作成功！");
                    _this.refreshData();
                }, function (_a) {
                    var msg = _a.msg;
                });
            })
                .catch(function () { });
        }
        else {
            this.$message("请选择要删除的案件！");
        }
    };
    /**
     * 添加工单
     */
    AlreadyDistributeCase.prototype.appendWorkorder = function (scope) {
        this.currentDate = scope.row;
        this.dialog.workerOrder = true;
    };
    __decorate([
        Dependencies(PageService)
    ], AlreadyDistributeCase.prototype, "pageService", void 0);
    __decorate([
        Dependencies(SortService)
    ], AlreadyDistributeCase.prototype, "sortService", void 0);
    __decorate([
        Dependencies(DistributeCaseService)
    ], AlreadyDistributeCase.prototype, "distributeCaseService", void 0);
    __decorate([
        Dependencies(CollectionCaseService)
    ], AlreadyDistributeCase.prototype, "collectionCaseService", void 0);
    __decorate([
        State
    ], AlreadyDistributeCase.prototype, "userData", void 0);
    __decorate([
        State
    ], AlreadyDistributeCase.prototype, "departmentList", void 0);
    __decorate([
        State
    ], AlreadyDistributeCase.prototype, "collectorList", void 0);
    __decorate([
        State
    ], AlreadyDistributeCase.prototype, "principalList", void 0);
    AlreadyDistributeCase = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataBox: DataBox,
                DataForm: DataForm,
                NumberRange: NumberRange,
                PrincipalBatch: PrincipalBatch,
                RetireCase: RetireCase,
                AdjustmentCaseAllot: AdjustmentCaseAllot,
                AdjustmentCaseBatchAllot: AdjustmentCaseBatchAllot,
                FollowRecordExport: FollowRecordExport,
                caseExport: caseExport,
                RefreshRetirementTime: RefreshRetirementTime,
                ImportBatchRetireCase: ImportBatchRetireCase,
                DeleteFollowRecord: DeleteFollowRecord,
                DelCaseBatchNumberForever: DelCaseBatchNumberForever,
                AppendWorkOrder: AppendWorkOrder,
                FollowAllQueryBatch: FollowAllQueryBatch,
                UpdateBaseCase: UpdateBaseCase,
            },
        })
    ], AlreadyDistributeCase);
    return AlreadyDistributeCase;
}(Vue));
export default AlreadyDistributeCase;
